import type { TopicType } from '@prisma/client'
import axios from 'axios'
import type { AxiosError } from 'axios'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

// export a constant with array of 1 to 10 values as string
export const avatarArray = Array.from({ length: 17 }, (_, i) =>
	(i + 1).toString(),
)

// randomize the avatar for each speaker
export const randomizeAvatarMap = (speakerNames: string[]) =>
	Object.fromEntries(
		speakerNames.map(name => [
			name,
			avatarArray[randomInt(avatarArray.length)],
		]),
	)

// max is exclusive
export function randomInt(max: number) {
	return Math.floor(Math.random() * max)
}

export const AllowedFiles = {
	'audio/*': ['.mp3', '.wav', '.flac', '.m4a', '.aac', '.ogg', '.opus'],
	'video/*': ['.mp4', '.mov', '.webm', '.mkv', '.avi', '.flv'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
		'.docx',
	],
	'text/plain': ['.txt'],
}

export const AudioExtTest = /\.(mp3|m4a|aac|wav|ogg|flac|opus)$/

export async function postUploadData(
	file: File,
	uploadId: string,
	ext: string,
	setUploadProgress: (progress: number) => void,
	setUploadError: (error: AxiosError) => void,
) {
	try {
		// get the signed url from the server
		const upload = await axios.get(`/upload/${uploadId}.${ext}`)
		console.log('Upload data:', upload.data)
		const res = await axios.put(upload.data.url, file, {
			headers: {
				'Content-Type': upload.data.type,
				Accept: 'application/json',
			},
			timeout: 3_000_000,
			onUploadProgress: evt => {
				const progress = Math.round((evt.loaded * 100) / (evt.total ?? 1))
				console.log('Upload progress:', progress)
				setUploadProgress(progress)
			},
		})

		console.log('Files uploaded successfully:')
		return res.status === 200
	} catch (error) {
		console.error('Error uploading files:', error)
		if (!axios.isAxiosError(error)) {
			throw error
		}
		setUploadError(error)
		return false
	}
}

export function displayAnalysisType(type: string) {
	if (type === 'hypothesis') {
		return 'Hypothesis'
	} else if (type === 'quote') {
		return 'Topic for the Quote'
	} else {
		return 'Research Question'
	}
}

export function displayAnalysisTypeHeader(type: TopicType) {
	if (type === 'question') {
		return 'Observations'
	} else if (type === 'hypothesis') {
		return 'Hypotheses'
	} else if (type === 'quote') {
		return 'Quotes'
	}
}

export function getRandomItems<T>(arr: T[], count: number) {
	if (count > arr.length) {
		return arr.slice()
	}

	const shuffled = arr.slice() // Create a shallow copy to avoid modifying the original array
	for (let i = shuffled.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1))
		;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]] // Swap elements
	}

	return shuffled.slice(0, count)
}

export function delay(ms: number) {
	return new Promise(resolve => setTimeout(resolve, ms))
}

export function keepNwords(text: string, n: number): string {
	return text
		.split('\n')
		.map(line => {
			const words = line.trim().split(/\s+/)
			return words.slice(0, n).join(' ')
		})
		.join('\n')
}
